var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"size":"xl","centered":"","lazy":"","title":_vm.order
          ? 'Informações do pedido ' +
            (_vm.order.number || _vm.order.documentNumber) +
            (_vm.selectedType === 'ENT'
              ? ' - Entrada'
              : _vm.selectedType === 'SAI'
              ? ' - Saída'
              : '')
          : '',"header-bg-variant":"info","footer-border-variant":"info","hide-footer":true,"id":"itens-and-their-lots-modal"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('b-container',[(_vm.loading)?_c('div',{staticClass:"mb-5 pb-5"},[_c('loading-animation')],1):(
            !_vm.order ||
            (_vm.movementType !== 'TRANSFORMACAO' &&
              (!_vm.order.orderItems || !_vm.order.orderItems.length))
          )?_c('div',[_c('div',{staticClass:"row bg-light mx-0 py-3"},[_c('div',{staticClass:"col text-center"},[(_vm.movementType === 'AJU' && _vm.selectedType === 'ENT')?_c('span',[_vm._v("Este ajuste não possui Entrada")]):(_vm.movementType === 'AJU' && _vm.selectedType === 'SAI')?_c('span',[_vm._v("Este ajuste não possui Saída")]):(
                  _vm.movementType === 'TRANSFORMACAO' && _vm.selectedType === 'ENT'
                )?_c('span',[_vm._v("Esta transformação não possui Entrada")]):(
                  _vm.movementType === 'TRANSFORMACAO' && _vm.selectedType === 'SAI'
                )?_c('span',[_vm._v("Esta transformação não possui Saída")]):_c('span',[_vm._v("Nenhum item encontrado")])])])]):_c('div',[_c('b-row',{staticClass:"justify-content-sm-between justify-content-center"},[_c('div',{staticClass:"col-lg-auto col-sm-12 col-12 mb-lg-3"},[_c('div',{staticClass:"row"},[(
                    _vm.order.formattedStartDate ||
                    _vm.order.formattedEndDate
                  )?_c('div',{staticClass:"col-lg-auto col-sm pl-lg-3 pl-0 pr-sm-3 pr-0"},[(_vm.order.formattedStartDate)?_c('h5',[_vm._v("\n                    Iniciado em: "+_vm._s(_vm.order.formattedStartDate)+"\n                  ")]):_vm._e(),(_vm.order.formattedEndDate)?_c('h5',[_vm._v("\n                    Finalizado em: "+_vm._s(_vm.order.formattedEndDate)+"\n                  ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-auto col-12 mt-lg-0 mt-3 px-lg-3 px-0"},[(!_vm.actionsLoading)?_c('b-dropdown',{attrs:{"id":"options-dropdown","text":"Ações","variant":"primary","size":"lg"}},[(
                    _vm.orderPrepareFunction &&
                    _vm.role === 'PEN' &&
                    (!_vm.order.movementStatus ||
                      _vm.order.movementStatus === 'L')
                  )?_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.orderPrepareFunction(_vm.order)}}},[_c('i',{staticClass:"fa fa-play-circle mr-1"}),_vm._v(" Iniciar\n                ")]):_vm._e(),(
                    _vm.order.movementStatus &&
                    _vm.order.movementStatus !== 'L' &&
                    _vm.order.fiscalOperation &&
                    _vm.order.fiscalOperation.type === 'SAI'
                  )?_c('b-dropdown-item-button',{on:{"click":_vm.generateSeparatedLotsReport}},[_c('i',{staticClass:"fa fa-file mr-1"}),_vm._v(" Imprimir Lotes Separados\n                ")]):_vm._e(),_c('b-dropdown-item-button',{on:{"click":_vm.openOrderAttendancesModal}},[_c('i',{staticClass:"fa fa-clock-o mr-1"}),_vm._v(" Apontamentos\n                ")]),(
                    ['O', 'C'].includes(_vm.order.movementStatus) &&
                    !_vm.order.transfer &&
                    !_vm.order.transformationMS &&
                    !_vm.order.productionMS &&
                    !_vm.order.stockAdjustment
                  )?_c('b-dropdown-item-button',{attrs:{"disabled":_vm.loading},on:{"click":_vm.returnToPendingConfirmationModal}},[_c('i',{staticClass:"fa fa-undo mr-1"}),_vm._v(" Retornar para pendente\n                ")]):_vm._e(),(
                    (_vm.order.transfer ||
                      (_vm.order.transformationMS &&
                        _vm.order.transformationMS.system !== 'C') ||
                      _vm.order.productionMS) &&
                    _vm.order.fiscalOperation &&
                    _vm.order.fiscalOperation.type === 'SAI'
                  )?_c('b-dropdown-item-button',{on:{"click":_vm.releaseEntraceOrder}},[_c('i',{staticClass:"fa fa-check mr-1"}),_vm._v(" Liberar Entrada\n                ")]):_vm._e(),(
                    _vm.order.movementStatus === 'O' &&
                    _vm.order.transformationMS &&
                    _vm.order.transformationMS.system === 'C'
                  )?_c('b-dropdown-item-button',{on:{"click":_vm.syncOrder}},[_c('i',{staticClass:"fa fa-refresh mr-1"}),_vm._v(" Sincronizar\n                ")]):_vm._e(),(
                    ['ENT', 'SAI'].includes(_vm.movementType) &&
                    _vm.order.movementStatus === 'X' &&
                    _vm.orderGeneratedStockAdjustment
                  )?_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.openGeneratedSockAdjustment(null)}}},[_c('i',{staticClass:"fa fa-repeat mr-1"}),_vm._v(" Abrir ajuste gerado com os movimentos inversos\n                ")]):_vm._e(),(
                    ['ENT', 'SAI'].includes(_vm.movementType) &&
                    _vm.order.movementStatus === 'X' &&
                    !_vm.orderGeneratedStockAdjustment
                  )?_c('b-dropdown-item-button',{on:{"click":_vm.openGenerateStockAdjustmentWithReverseMovementsConfirmationModal}},[_c('i',{staticClass:"fa fa-repeat mr-1"}),_vm._v(" Gerar ajuste com os movimentos inversos\n                ")]):_vm._e(),_c('b-dropdown-item-button',{on:{"click":function($event){$event.preventDefault();return _vm.goToTrackPage.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-search-plus mr-1"}),_vm._v(" Rastrear\n                ")])],1):_c('div',{staticClass:"col-auto"},[_c('loading-animation',{staticClass:"mx-5"})],1)],1)]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-lg col-12 px-lg-3 px-0"},[_c('b',[_vm._v("Criado/Sincronizado em: ")]),_c('span',{staticClass:"float-right float-lg-none"},[_vm._v(_vm._s(_vm.order.formattedCreatedAt))])]),_c('div',{staticClass:"col-lg col-12 px-lg-3 px-0"},[_c('b',[_vm._v("Data de Embarque: ")]),_c('span',{staticClass:"float-right float-lg-none"},[_vm._v(_vm._s(_vm.order.formattedDepartureDate))])]),_c('div',{staticClass:"col-lg col-12 px-lg-3 px-0"},[_c('b',[_vm._v("Data Prevista de Entrega: ")]),_c('span',{staticClass:"float-right float-lg-none"},[_vm._v(_vm._s(_vm.order.formattedDeliveryDate))])])]),_c('div',{staticClass:"row mt-3"},_vm._l((_vm.order.orderItems),function(orderItem,index){return _c('div',{key:index,staticClass:"col-xl-4 col-lg-6 col-12 px-lg-3 px-0"},[(orderItem && orderItem.item)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header",style:(_vm.getOrderItemCardHeaderStyle(orderItem)),on:{"click":function($event){return _vm.toggleSelectedItemOrderItemLots(index)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(orderItem.item.description))]),_c('p',{staticClass:"text-muted mb-1"},[_vm._v("\n                        "+_vm._s(_vm.getItemReduzidoFromRef(orderItem.item.ref))+"\n                      ")]),(
                          _vm.order.stockAdjustment &&
                          _vm.order.stockAdjustment.type === 'EEL'
                        )?_c('p',[_vm._v("\n                        Quantidade: "+_vm._s(orderItem.quantity)+"\n                      ")]):_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"mb-0"},[_vm._v("Total: "+_vm._s(orderItem.quantity))]),_c('p',{staticClass:"mb-0"},[_vm._v("\n                          Alocado: "+_vm._s(orderItem.alocatedQuantity)+"\n                        ")]),_c('p',{staticClass:"mb-0"},[_vm._v("\n                          Pendente:\n                          "+_vm._s(orderItem.quantity -
                            (orderItem.alocatedQuantity || 0))+"\n                        ")])])]),(
                        _vm.loadingSelectedItemLots ||
                        (orderItem.itemLots && orderItem.itemLots.length)
                      )?_c('div',{staticClass:"col-auto"},[(_vm.loadingSelectedItemLots)?_c('loading-animation'):(
                          orderItem.itemLots && orderItem.itemLots.length
                        )?_c('i',{staticClass:"fa fa-lg float-right pr-1 pt-1",class:_vm.showSelectedItemLots[index]
                            ? 'fa-caret-down'
                            : 'fa-caret-right'}):_vm._e()],1):_vm._e()])]),(_vm.showSelectedItemLots[index])?_c('div',{staticClass:"card-body p-0"},_vm._l((orderItem.itemLots),function(itemLot,index){return _c('div',{key:index,staticClass:"row p-3 border mx-0",class:{ 'bg-light': index % 2 != 0 }},[_c('div',{staticClass:"col pl-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Número: ")]),_vm._v(_vm._s(itemLot.lotNumber)+"\n                    ")]),_c('div',{staticClass:"col pr-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Quantidade: ")]),_vm._v(_vm._s(itemLot.quantity)+"\n                    ")])])}),0):_vm._e()]):_vm._e()])}),0)],1)])],1),(_vm.order && _vm.order.id)?_c('order-attendances-table-modal',{ref:"orderAttendancesModal",attrs:{"title":_vm.order
          ? 'Apontamentos do pedido ' +
            (_vm.order.number || _vm.order.documentNumber) +
            (_vm.selectedType === 'ENT'
              ? ' - Entrada'
              : _vm.selectedType === 'SAI'
              ? ' - Saída'
              : '')
          : '',"id":"order-attendances-modal","orderId":_vm.order.id}}):_vm._e(),_c('confirmation-modal',{attrs:{"id":"generate-stock-adjustment-with-reverse-movements-confirmation-modal","msg":"Tem certeza de que deseja gerar um ajuste com os movimentos inversos desse pedido?","ok":_vm.generateStockAdjustmentWithReverseMovements}}),_c('confirmation-modal',{attrs:{"id":"generate-stock-adjustment-with-reverse-movements-after-cancelling-confirmation-modal","msg":"Deseja gerar um ajuste com os movimentos inversos desse pedido?","ok":_vm.generateStockAdjustmentWithReverseMovements}}),_c('confirmation-modal',{attrs:{"id":"return-to-pending-confirmation-modal","msg":"Tem certeza que deseja retornar o pedido para pendente?","ok":_vm.returnToPending}}),_c('confirmation-modal',{attrs:{"id":"separate-imediately-confirmation-modal","msg":"Deseja retomar a alocação/separação imediatamente?","ok":_vm.callOrderPrepareFunction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
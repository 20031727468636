<template>
  <div>

    <b-modal
        size="xl"
        centered
        lazy
        :title="
          order
            ? 'Informações do pedido ' +
              (order.number || order.documentNumber) +
              (selectedType === 'ENT'
                ? ' - Entrada'
                : selectedType === 'SAI'
                ? ' - Saída'
                : '')
            : ''
        "
        header-bg-variant="info"
        footer-border-variant="info"
        v-model="show"
        :hide-footer="true"
        id="itens-and-their-lots-modal"
      >
        <b-container>
          <div v-if="loading" class="mb-5 pb-5">
            <loading-animation />
          </div>

          <div
            v-else-if="
              !order ||
              (movementType !== 'TRANSFORMACAO' &&
                (!order.orderItems || !order.orderItems.length))
            "
          >
            <div class="row bg-light mx-0 py-3">
              <div class="col text-center">
                <span v-if="movementType === 'AJU' && selectedType === 'ENT'"
                  >Este ajuste não possui Entrada</span
                >
                <span
                  v-else-if="movementType === 'AJU' && selectedType === 'SAI'"
                  >Este ajuste não possui Saída</span
                >
                <span
                  v-else-if="
                    movementType === 'TRANSFORMACAO' && selectedType === 'ENT'
                  "
                  >Esta transformação não possui Entrada</span
                >
                <span
                  v-else-if="
                    movementType === 'TRANSFORMACAO' && selectedType === 'SAI'
                  "
                  >Esta transformação não possui Saída</span
                >
                <span v-else>Nenhum item encontrado</span>
              </div>
            </div>
          </div>

          <div v-else>
            <b-row class="justify-content-sm-between justify-content-center">

              <div class="col-lg-auto col-sm-12 col-12 mb-lg-3">
                <div class="row">
                  <div
                    class="col-lg-auto col-sm pl-lg-3 pl-0 pr-sm-3 pr-0"
                    v-if="
                      order.formattedStartDate ||
                      order.formattedEndDate
                    "
                  >
                    <h5 v-if="order.formattedStartDate">
                      Iniciado em: {{ order.formattedStartDate }}
                    </h5>
                    <h5 v-if="order.formattedEndDate">
                      Finalizado em: {{ order.formattedEndDate }}
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-lg-auto col-12 mt-lg-0 mt-3 px-lg-3 px-0">
                <b-dropdown
                  v-if="!actionsLoading"
                  id="options-dropdown"
                  text="Ações"
                  variant="primary"
                  size="lg"
                >

                  <b-dropdown-item-button
                    v-if="
                      orderPrepareFunction &&
                      role === 'PEN' &&
                      (!order.movementStatus ||
                        order.movementStatus === 'L')
                    "
                    @click="orderPrepareFunction(order)"
                  >
                    <i class="fa fa-play-circle mr-1"></i> Iniciar
                  </b-dropdown-item-button>

                  <b-dropdown-item-button
                    v-if="
                      order.movementStatus &&
                      order.movementStatus !== 'L' &&
                      order.fiscalOperation &&
                      order.fiscalOperation.type === 'SAI'
                    "
                    @click="generateSeparatedLotsReport"
                  >
                    <i class="fa fa-file mr-1"></i> Imprimir Lotes Separados
                  </b-dropdown-item-button>

                  <b-dropdown-item-button
                    @click="openOrderAttendancesModal"
                  >
                    <i class="fa fa-clock-o mr-1"></i> Apontamentos
                  </b-dropdown-item-button>

                  <b-dropdown-item-button
                    v-if="
                      ['O', 'C'].includes(order.movementStatus) &&
                      !order.transfer &&
                      !order.transformationMS &&
                      !order.productionMS &&
                      !order.stockAdjustment
                    "
                    @click="returnToPendingConfirmationModal"
                    :disabled="loading"
                  >
                    <i class="fa fa-undo mr-1"></i> Retornar para pendente
                  </b-dropdown-item-button>

                  <b-dropdown-item-button
                    v-if="
                      (order.transfer ||
                        (order.transformationMS &&
                          order.transformationMS.system !== 'C') ||
                        order.productionMS) &&
                      order.fiscalOperation &&
                      order.fiscalOperation.type === 'SAI'
                    "
                    @click="releaseEntraceOrder"
                  >
                    <i class="fa fa-check mr-1"></i> Liberar Entrada
                  </b-dropdown-item-button>

                  <b-dropdown-item-button
                    v-if="
                      order.movementStatus === 'O' &&
                      order.transformationMS &&
                      order.transformationMS.system === 'C'
                    "
                    @click="syncOrder"
                  >
                    <i class="fa fa-refresh mr-1"></i> Sincronizar
                  </b-dropdown-item-button>

                  <b-dropdown-item-button
                    v-if="
                      ['ENT', 'SAI'].includes(movementType) &&
                      order.movementStatus === 'X' &&
                      orderGeneratedStockAdjustment
                    "
                    @click="openGeneratedSockAdjustment(null)"
                  >
                    <i class="fa fa-repeat mr-1"></i> Abrir ajuste gerado com os movimentos inversos
                  </b-dropdown-item-button>

                  <b-dropdown-item-button
                    v-if="
                      ['ENT', 'SAI'].includes(movementType) &&
                      order.movementStatus === 'X' &&
                      !orderGeneratedStockAdjustment
                    "
                    @click="openGenerateStockAdjustmentWithReverseMovementsConfirmationModal"
                  >
                    <i class="fa fa-repeat mr-1"></i> Gerar ajuste com os movimentos inversos
                  </b-dropdown-item-button>

                  <b-dropdown-item-button
                    @click.prevent="goToTrackPage"
                  >
                    <i class="fa fa-search-plus mr-1"></i> Rastrear
                  </b-dropdown-item-button>

                </b-dropdown>
                <div v-else class="col-auto">
                  <loading-animation class="mx-5"/>
                </div>
              </div>

            </b-row>

            <div class="row mt-2">

              <div class="col-lg col-12 px-lg-3 px-0">
                <b>Criado/Sincronizado em: </b>
                <span class="float-right float-lg-none">{{ order.formattedCreatedAt }}</span>
              </div>

              <div class="col-lg col-12 px-lg-3 px-0">
                <b>Data de Embarque: </b>
                <span class="float-right float-lg-none">{{ order.formattedDepartureDate }}</span>
              </div>

              <div class="col-lg col-12 px-lg-3 px-0">
                <b>Data Prevista de Entrega: </b>
                <span class="float-right float-lg-none">{{ order.formattedDeliveryDate }}</span>
              </div>

            </div>

            <div class="row mt-3">
              <div
                v-for="(orderItem, index) in order.orderItems"
                :key="index"
                class="col-xl-4 col-lg-6 col-12 px-lg-3 px-0"
              >
                <div v-if="orderItem && orderItem.item" class="card">
                  <div
                    class="card-header"
                    @click="toggleSelectedItemOrderItemLots(index)"
                    :style="getOrderItemCardHeaderStyle(orderItem)"
                  >
                    <div class="row">
                      <div class="col">
                        <h5 class="mb-0">{{ orderItem.item.description }}</h5>
                        <p class="text-muted mb-1">
                          {{ getItemReduzidoFromRef(orderItem.item.ref) }}
                        </p>
                        <p
                          v-if="
                            order.stockAdjustment &&
                            order.stockAdjustment.type === 'EEL'
                          "
                        >
                          Quantidade: {{ orderItem.quantity }}
                        </p>
                        <div v-else class="text-right">
                          <p class="mb-0">Total: {{ orderItem.quantity }}</p>
                          <p class="mb-0">
                            Alocado: {{ orderItem.alocatedQuantity }}
                          </p>
                          <p class="mb-0">
                            Pendente:
                            {{
                              orderItem.quantity -
                              (orderItem.alocatedQuantity || 0)
                            }}
                          </p>
                        </div>
                      </div>
                      <div
                        v-if="
                          loadingSelectedItemLots ||
                          (orderItem.itemLots && orderItem.itemLots.length)
                        "
                        class="col-auto"
                      >
                        <loading-animation v-if="loadingSelectedItemLots" />
                        <i
                          v-else-if="
                            orderItem.itemLots && orderItem.itemLots.length
                          "
                          class="fa fa-lg float-right pr-1 pt-1"
                          :class="
                            showSelectedItemLots[index]
                              ? 'fa-caret-down'
                              : 'fa-caret-right'
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSelectedItemLots[index]" class="card-body p-0">
                    <div
                      v-for="(itemLot, index) in orderItem.itemLots"
                      class="row p-3 border mx-0"
                      :key="index"
                      :class="{ 'bg-light': index % 2 != 0 }"
                    >
                      <div class="col pl-0">
                        <span class="font-weight-bold">Número: </span
                        >{{ itemLot.lotNumber }}
                      </div>
                      <div class="col pr-0">
                        <span class="font-weight-bold">Quantidade: </span
                        >{{ itemLot.quantity }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </b-modal>

      <order-attendances-table-modal
        v-if="order && order.id"
        :title="
          order
            ? 'Apontamentos do pedido ' +
              (order.number || order.documentNumber) +
              (selectedType === 'ENT'
                ? ' - Entrada'
                : selectedType === 'SAI'
                ? ' - Saída'
                : '')
            : ''
        "
        id="order-attendances-modal"
        ref="orderAttendancesModal"
        :orderId="order.id"
      ></order-attendances-table-modal>

      <confirmation-modal
        id="generate-stock-adjustment-with-reverse-movements-confirmation-modal"
        msg="Tem certeza de que deseja gerar um ajuste com os movimentos inversos desse pedido?"
        :ok="generateStockAdjustmentWithReverseMovements"
      ></confirmation-modal>

      <confirmation-modal
        id="generate-stock-adjustment-with-reverse-movements-after-cancelling-confirmation-modal"
        msg="Deseja gerar um ajuste com os movimentos inversos desse pedido?"
        :ok="generateStockAdjustmentWithReverseMovements"
      ></confirmation-modal>

      <confirmation-modal
        id="return-to-pending-confirmation-modal"
        msg="Tem certeza que deseja retornar o pedido para pendente?"
        :ok="returnToPending"
      ></confirmation-modal>

      <confirmation-modal
        id="separate-imediately-confirmation-modal"
        msg="Deseja retomar a alocação/separação imediatamente?"
        :ok="callOrderPrepareFunction"
      ></confirmation-modal>
  </div>
</template>

<script>
import OrderAttendancesTableModal from "@/components/tables/order-attendances-table-modal";
import { LoadingAnimation } from "@/components/loaders";
import { httpClient } from "@/service";
import Axios from 'axios'
import { auth } from "@/service";
import ConfirmationModal from "@/components/common/confirmation-modal";
import shared from "@/shared/shared";

export default {
  name: "order-info-modal",

  components: {
    OrderAttendancesTableModal,
    LoadingAnimation,
    ConfirmationModal
  },

  props: {
    movementType: {
      type: String,
      require
    },
    role: {
      type: String,
      require,
    },
    saving: {
      type: Boolean,
      require
    },
    updateSaving: {
      type: Function,
      require
    },
    startPrepare: {
      type: Function,
      require
    },
    config: {
      type: Object,
      require
    },
    url: {
      type: String,
      require
    }
  },

  data() {
    return {
      order: null,
      show: false,
      loading: false,
      selectedType: null,
      loadingSeparatedLotsReport: false,
      loadingEntranceOrderRelease: false,
      orderPrepareFunction: null,
      loadingOrderSync: false,
      loadingSelectedItemGeneratedStockAdjustment: false,
      orderGeneratedStockAdjustment: null,
      tempOrderId: null,
      showSelectedItemLots: [],
      loadingSelectedItemLots: false,
    }
  },

  mounted() {
  },

  computed: {
    actionsLoading() {
      return this.loading
        || this.saving
        || this.loadingSeparatedLotsReport
        || this.loadingEntranceOrderRelease
        || this.loadingOrderSync
        || this.loadingSelectedItemGeneratedStockAdjustment;
    }
  },

  methods: {
    showModal() {
      this.show = true;
    },

    hideModal() {
      this.show = false;
    },

    goToTrackPage() {
      let direction = "DESC";

      if (this.order.fiscalOperation && this.order.fiscalOperation.type === "ENT") {
        direction = "ASC";
      }

      this.$router.push(
        "/orders/track/" + this.order.id + "?direction=" + direction
      );
    },

    returnToPending() {
      this.loading = true

      httpClient.patch(`${process.env.VUE_APP_API_URL}orders/return-to-pending/${this.order.id}`
      ).then(data => {
        this.loading = false;
        this.separateImediatelyConfirmationModal()
        this.$notify.success('Pedido retornado para pendente')
      }).catch(error => {
        this.loading = false;
        if (error.message) {
          this.$notify.error(error);
        } else {
          this.$notify.textError('Houve um erro ao retornar pedido para pendente');
        }
      });
    },

    callOrderPrepareFunction() {
      this.orderPrepareFunction(this.order);
    },

    openOrderAttendancesModal() {
      this.$bvModal.show("order-attendances-modal");
      this.$refs.orderAttendancesModal.orderAttendancesPageChanged(1);
    },

    generateSeparatedLotsReport() {
      this.loadingSeparatedLotsReport = true;
      Axios({
        url: `${process.env.VUE_APP_API_URL}orders/separated-lots-report/${this.order.id}`,
        method: 'POST',
        data: this.config.printConfig.separatedLots,
        responseType: 'blob',
        headers: auth.getAxiosHeaders()
      }).then((response) => {
        this.loadingSeparatedLotsReport = false;
        if (response && response.data && response.data.size) {
          let file = new Blob([response.data], { type: 'application/pdf' });
          let url = window.URL.createObjectURL(file);
          window.open(url);
        } else {
          this.$notify.textError('Houve um erro ao gerar o PDF. Por favor tente novamente');
        }
      }).catch((error) => {
        this.loadingSeparatedLotsReport = false;

        if (error.response && error.response.headers && error.response.headers.errormessage) {
          this.$notify.textError(error.response.headers.errormessage);
        } else if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError('Houve um erro ao gerar o PDF. Por favor tente novamente');
        }
      });
    },

    releaseEntraceOrder() {
      this.loadingEntranceOrderRelease = true;
      httpClient
        .post(
          `${process.env.VUE_APP_API_URL}orders/entrance-order-release/${this.order.id}`,
          {}
        )
        .then((data) => {
          this.loadingEntranceOrderRelease = false;
          this.$notify.success("Pedido de entrada liberado com sucesso");
        })
        .catch((error) => {
          this.loadingEntranceOrderRelease = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError(
              "Houve um erro ao liberar o pedido de entrada"
            );
          }
        });
    },

    syncOrder() {
      this.loadingOrderSync = true;
      httpClient
        .post(`${process.env.VUE_APP_API_URL}orders/sync-order/${this.order.id}`, {})
        .then((data) => {
          this.loadingOrderSync = false;
          this.$notify.success(
            "Pedido adicionado com sucesso à fila de sincronização"
          );
        })
        .catch((error) => {
          this.loadingOrderSync = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError(
              "Houve um erro ao adicionar o pedido à fila de sincronização"
            );
          }
        });
    },

    openGeneratedSockAdjustment(id) {
      this.$router.push(
        "/orders/location-and-lot-item-adjustment/edit/" +
          (id ? id : this.orderGeneratedStockAdjustment.id)
      );
    },

    openGenerateStockAdjustmentWithReverseMovementsConfirmationModal() {
      this.tempOrderId = null;
      this.$bvModal.show(
        "generate-stock-adjustment-with-reverse-movements-confirmation-modal"
      );
    },

    returnToPendingConfirmationModal() {
      this.tempOrderId = null;
      this.$bvModal.show(
        "return-to-pending-confirmation-modal"
      );
    },

    separateImediatelyConfirmationModal() {
      this.tempOrderId = null;
      this.$bvModal.show(
        "separate-imediately-confirmation-modal"
      );
    },

    generateStockAdjustmentWithReverseMovements() {
      let id;
      if (this.tempOrderId) {
        id = this.tempOrderId;
      } else {
        id = this.order.id;
      }

      this.updateSaving(true);
      httpClient
        .post(this.url + "/stock-adjustment-with-reverse-movements/" + id)
        .then((data) => {
          this.updateSaving(false);
          this.$notify.success("Ajuste gerado com sucesso");
          this.openGeneratedSockAdjustment(data.data.data.id);
        })
        .catch((error) => {
          this.updateSaving(false);
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError("Houve um erro ao gerar o ajuste com movimentos inversos");
          }
        });
    },

    openOrderInfoModal(item, type) {
      this.selectedType = type;
      this.showModal();
      this.showSelectedItemLots = [];

      if (["AJU", "TRANSFORMACAO"].includes(this.movementType)) {
        this.loadAdustmentOrTransformationOrder(item);
      } else {
        this.loading = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}orders/${item.id}`)
        .then(({ data }) => {
          this.loading = false;

          if (data && data.data) {
            this.order = data.data;
            this.orderPrepareFunction = this.startPrepare;

            if (this.order.allocationStartDate) {
              this.order.formattedStartDate = shared.formatDate(this.order.allocationStartDate);
            }

            if (this.order.allocationEndDate) {
              this.order.formattedEndDate = shared.formatDate(this.order.allocationEndDate);
            }

            if (this.order.createdAt) {
              this.order.formattedCreatedAt = shared.formatDate(this.order.createdAt);
            }

            if (this.order.departureDate) {
              this.order.formattedDepartureDate = shared.formatDate(this.order.departureDate);
            }

            if (this.order.deliveryDate) {
              this.order.formattedDeliveryDate = shared.formatDate(this.order.deliveryDate);
            }

            this.loadShowSelectedItemLots();

            if (this.movementType !== "RAL") {
              this.loadSelectedItemGeneratedStockAdjustment();
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.message) {
            this.$notify.error(error);
          }
        });
      }
    },

    loadAdustmentOrTransformationOrder(item) {
      if (this.selectedType && this.selectedType.length) {
        this.order = null;

        if (this.movementType === "TRANSFORMACAO" || item.type === "ARM") {
          this.loadTransformationOrArmAdjustment(item);
        } else if (item.type === "EEL") {
          this.loadEelAdjustment(item);
        }
      }
    },

    loadTransformationOrArmAdjustment(item) {
      if (this.selectedType === "ENT") {
        this.orderPrepareFunction = this.startAdjustmentOrTransformationEntrancePrepare;
      } else if (this.selectedType === "SAI") {
        this.orderPrepareFunction = this.startAdjustmentOrTransformationExitPrepare;
      } else {
        this.orderPrepareFunction = null;
        return;
      }

      let conditions = [
        {
          field: "fiscalOperation.type",
          value: this.selectedType,
        },
      ];

      if (this.movementType === "AJU") {
        conditions.push({
          field: "stockAdjustment.id",
          value: item.id,
        });
      } else if (this.movementType === "TRANSFORMACAO") {
        conditions.push({
          field: "transformationMS.id",
          value: item.id,
        });
      } else {
        return;
      }

      this.loading = true;
      httpClient
      .post(
        `${
          process.env.VUE_APP_API_URL
        }orders/condition?page=${0}&size=${1}`,
        {
          conditions: conditions,
        }
      )
      .then(({ data }) => {
        this.loading = false;

        if (
          data &&
          data.data &&
          data.data.content &&
          data.data.content.length
        ) {
          this.order = data.data.content[0];
          this.loadShowSelectedItemLots();
        }
      })
      .catch((error) => {
        this.loading = false;
        if (error.message) {
          this.$notify.error(error);
        }
      });
    },

    loadEelAdjustment(item) {
      this.orderPrepareFunction = null;
      this.loading = true;

      httpClient
      .post(
        `${
          process.env.VUE_APP_API_URL
        }orders/condition?page=${0}&size=${1}`,
        {
          conditions: [
            {
              field: "stockAdjustment.id",
              value: item.id,
            },
            {
              field: "fiscalOperation.type",
              value: this.selectedType,
            },
          ],
        }
      )
      .then(({ data }) => {
        this.loading = false;

        if (
          data &&
          data.data &&
          data.data.content &&
          data.data.content.length
        ) {
          this.order = data.data.content[0];

          if (
            this.order.allocationStartDate ||
            this.order.allocationEndDate
          ) {
            this.order.formattedStartDate = shared.formatDate(
              this.order.allocationStartDate
            );
            this.order.formattedEndDate = shared.formatDate(
              this.order.allocationEndDate
            );
          } else if (
            this.order.createdAt &&
            new Date(this.order.createdAt).getTime() <
              1594236223720
          ) {
            this.order.formattedStartDate = shared.formatDate(
              this.order.createdAt
            );
            this.order.formattedEndDate = this.order.formattedStartDate;
          }

          this.loadShowSelectedItemLots();
        }
      })
      .catch((error) => {
        this.loading = false;
        if (error.message) {
          this.$notify.error(error);
        }
      });
    },

    loadSelectedItemGeneratedStockAdjustment() {
      this.orderGeneratedStockAdjustment = null;
      this.loadingSelectedItemGeneratedStockAdjustment = true;
      httpClient
        .post(
          `${process.env.VUE_APP_API_URL}stock-adjustments/condition?page=0&size=1`,
          {
            field: "generatedFromOrder.id",
            value: this.order.id,
          }
        )
        .then((data) => {
          this.loadingSelectedItemGeneratedStockAdjustment = false;

          if (
            data.data.data &&
            data.data.data.content &&
            data.data.data.content.length
          ) {
            this.orderGeneratedStockAdjustment =
              data.data.data.content[0];
          }
        })
        .catch((error) => {
          this.loadingSelectedItemGeneratedStockAdjustment = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError(
              "Houve um erro ao buscar o ajuste gerado pelo pedido"
            );
          }
        });
    },

    toggleSelectedItemOrderItemLots(i) {
      if (
        this.order &&
        this.order.orderItems &&
        this.order.orderItems[i] &&
        this.order.orderItems[i].itemLots &&
        this.order.orderItems[i].itemLots.length
      ) {
        this.showSelectedItemLots[i] = !this.showSelectedItemLots[i];

        let showSelectedItemLots = this.showSelectedItemLots;
        this.showSelectedItemLots = [];
        this.showSelectedItemLots = showSelectedItemLots;
      }
    },

    getOrderItemCardHeaderStyle(orderItem) {
      if (orderItem && orderItem.itemLots && orderItem.itemLots.length) {
        return "cursor: pointer;";
      } else {
        return "";
      }
    },

    loadShowSelectedItemLots() {
      if (this.order && this.order.orderItems) {
        for (let i = 0; i < this.order.orderItems.length; i++) {
          this.showSelectedItemLots.push(true);
        }

        this.getSelectedItemLots();
      }
    },

    getSelectedItemLots() {
      if (
        this.order &&
        this.order.movementStatus &&
        this.order.movementStatus !== "L" &&
        this.order.orderItems &&
        this.order.orderItems.length
      ) {
        this.loadingSelectedItemLots = true;
        httpClient
          .get(
            `${process.env.VUE_APP_API_URL}orders/${this.order.id}/order-items/separated-lots`
          )
          .then((data) => {
            this.loadingSelectedItemLots = false;
            let ois = data.data.data;

            if (ois && ois.length) {
              this.order.orderItems.forEach((orderItem) => {
                orderItem.itemLots = [];
                let oi = ois.find(oi => oi.id === orderItem.id);

                if (oi && oi.separatedLots) {
                  orderItem.itemLots = oi.separatedLots.map(ilm => {
                    if (ilm.itemLot) {
                      ilm.itemLot.quantity = ilm.quantity;
                    }

                    return ilm.itemLot;
                  });
                }
              });
            }
          })
          .catch((error) => {
            this.loadingSelectedItemLots = false;
            if (error.message) {
              this.$notify.error(error);
            } else {
              this.$notify.textError(
                "Houve um erro ao buscar os lotes do pedido"
              );
            }
          });
      }
    },

    getItemReduzidoFromRef(ref) {
      return shared.getItemReduzidoFromRef(ref);
    },

    startAdjustmentOrTransformationEntrancePrepare(order) {
      this.$router.push("/orders/item-in/start/" + order.id);
    },

    startAdjustmentOrTransformationExitPrepare(order) {
      this.$router.push("/orders/item-out/start/" + order.id);
    },

  }
}
</script>

<style>
  #options-dropdown {
    min-width: 250px;
  }

  @media (max-width: 993px) {
    #options-dropdown {
      width: 100%;
    }
  }
</style>

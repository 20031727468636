<template>
  <div class="card">
    <div class="card-body p-0">
      <div v-if="!loadingRows && !getItems().length" class="mb-3">
        <div class="row bg-light mx-0 py-3">
          <div class="col text-center">Nenhum Pedido encontrado</div>
        </div>
      </div>

      <div v-if="getItems().length" class="mb-3">
        <div
          v-for="(item, index) in getItems()"
          class="row py-3 border mx-0"
          :key="index"
          :class="{ 'bg-light': index % 2 != 0 }"
        >
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12">
            <div class="row">
              <div class="col-12">
                <span class="font-weight-bold">Documento: </span
                >{{ item.number ? item.number : "Sem documento" }}
                <span v-if="item.imported || (item.stockAdjustment && item.stockAdjustment.imported)" class="badge badge-secondary">Importado</span>
              </div>
              <div
                v-if="
                  !['AJU', 'RAL', 'TRANSFORMACAO'].includes(movementType) &&
                  !item.stockAdjustment
                "
                class="col-12 text-muted mt-n1"
              >
                <small>
                  <span class="font-weight-bold">Transação: </span
                  >{{ item.refLabel }}
                </small>
              </div>
              <div
                v-if="
                  item.documentNumber &&
                  item.documentNumber.toString().length &&
                  item.documentNumber != item.number
                "
                class="col-12"
              >
                <span class="font-weight-bold">Código Interno: </span
                >{{ item.documentNumber }}
              </div>
              <div
                v-if="!['AJU', 'RAL', 'TRANSFORMACAO'].includes(movementType)"
                class="col-12"
              >
                <span class="font-weight-bold">Operação: </span>
                <span
                  v-if="item.fiscalOperation"
                  class="badge"
                  :class="operationBadgeClassObject(item)"
                >
                  {{ item.fiscalOperation.showType }}
                </span>
              </div>
              <div v-else-if="movementType === 'AJU'" class="col-12">
                <span class="font-weight-bold">Tipo: </span>
                <span
                  class="badge"
                  :class="adjustmentTypeBadgeClassObject(item)"
                >
                  {{ getAdjustmentTypeDescription(item) }}
                </span>
              </div>
              <div class="col-12" v-if="movementType == 'ENT' || movementType == 'SAI'">
                <span class="font-weight-bold">Empresa: </span>
                {{ item.company && item.company.ref ? item.company.ref : "" }}
              </div>
            </div>
            <div v-if="item.generatedFromOrder" class="row mt-3">
              <div class="col-12">
                Gerado a partir do pedido {{ item.generatedFromOrder.number }}
                <span
                  v-if="item.generatedFromOrder.fiscalOperation"
                  class="badge"
                  :class="operationBadgeClassObject(item.generatedFromOrder)"
                >
                  {{ item.generatedFromOrder.fiscalOperation.showType }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-5 col-md-5 col-sm-8 col-12">
            <div v-if="movementType === 'TRANSFORMACAO'" class="row">
              <div class="col-12">
                <span class="font-weight-bold">Origem: </span
                >{{
                  item.entriesStockLocale ? item.entriesStockLocale.name : ""
                }}
              </div>
              <div class="col-12">
                <span class="font-weight-bold">Destino: </span
                >{{ item.exitsStockLocale ? item.exitsStockLocale.name : "" }}
              </div>
              <div class="col-12">
                <small>
                  <span class="font-weight-bold">Data: </span>{{ item.formattedCreatedAt }}
                </small>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12">
                <span
                  v-if="!['RAL', 'AJU'].includes(movementType)"
                  class="font-weight-bold"
                >
                  {{
                    movementType === "ENT"
                      ? "Origem"
                      : movementType === "SAI"
                      ? "Destino"
                      : ""
                  }}:
                </span>
                {{
                  item.customer && item.customer.person
                    ? item.customer.person.name
                    : ""
                }}
              </div>
              <div class="col-12">
                <span class="font-weight-bold">Armazém: </span
                >{{ item.stockLocale ? item.stockLocale.name : "" }}
              </div>
              <div class="col-12" v-if="movementType === 'SAI'">
                <span class="font-weight-bold">Status: </span
                >{{ item.ticketStatus ? (item.ticketStatus.situation + ' - ' + item.ticketStatus.description) : "" }}
              </div>
              <div
                v-if="movementType === 'AJU' && item.type === 'EEL'"
                class="col-12"
              >
                <span class="font-weight-bold">Contar: </span>
                <span v-if="!item.countOption || item.countOption === 'E'"
                  >Endereços específicos</span
                >
                <span v-if="item.countOption === 'G'"
                  >Endereços de um grupo</span
                >
                <span v-if="item.countOption === 'W'"
                  >Todos os endereços do armazém</span
                >
              </div>
              <div class="col-12">
                <small>
                  <span class="font-weight-bold">Data: </span>{{ item.formattedCreatedAt }}
                </small>
              </div>
              <div class="col-12">
                <small>
                  <span class="font-weight-bold">Embarque: </span>{{ item.formattedDepartureDate }}
                </small>
              </div>
              <div class="col-12">
                <small>
                  <span class="font-weight-bold">Previsão de entrega: </span>{{ item.formattedDeliveryDate }}
                </small>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 mt-md-0 mt-3">
            <div v-if="saving">
              <loading-animation />
            </div>
            <div v-else class="row">
              <div class="col-md-12 col-sm-5 col-12">
                <b-button
                  v-if="
                    role !== 'CAN' &&
                    ['AJU', 'TRANSFORMACAO'].includes(movementType)
                  "
                  variant="outline-success"
                  class="mr-sm-1 mr-3 mobile-btn-45"
                  @click="openOrderInfoModal(item, 'SAI')">
                  Saída
                </b-button>
                <b-button
                  v-if="
                    role !== 'CAN' &&
                    ['AJU', 'TRANSFORMACAO'].includes(movementType)
                  "
                  variant="outline-success"
                  class="mr-sm-1 mr-3 mobile-btn-45"
                  @click="openOrderInfoModal(item, 'ENT')">
                  Entrada
                </b-button>

                <b-button
                  v-if="
                    !['AJU', 'TRANSFORMACAO'].includes(movementType) &&
                    role === 'PEN'
                  "
                  variant="outline-success"
                  class="mr-sm-1 mr-3 mobile-btn-45"
                  @click="startPrepare(item)">
                  Iniciar
                </b-button>

                <b-button
                  v-if="!['AJU', 'TRANSFORMACAO'].includes(movementType)"
                  variant="outline-info"
                  class="mr-sm-1 mr-3 mobile-btn-45"
                  @click="openOrderInfoModal(item)">
                  Info
                </b-button>
              </div>
              <div
                v-if="
                  item.movementStatus !== 'O' &&
                  !['AJU', 'TRANSFORMACAO'].includes(movementType)
                  && item.alocatedQuotient !== null && item.alocatedQuotient !== undefined
                "
                class="col-md-12 col-sm col-12 mt-md-1 mt-sm-0 mt-1 ml-md-0 ml-sm-n5"
              >
                <small class="font-weight-bold">Progresso: </small>
                <b-progress
                  :value="item.alocatedQuotient * 100"
                  :max="100"
                  show-progress
                  style="height: 8px"
                  class="bg-secondary"
                >
                </b-progress>
              </div>
              <div
                v-if="
                  (enableEdit || enableOpen || enableDelete || enableCancel) &&
                  (!['AJU', 'TRANSFORMACAO'].includes(movementType) ||
                    item.type === 'ARM' ||
                    item.type === 'EEL' ||
                    (movementType === 'TRANSFORMACAO' && item.system === 'C'))
                "
                class="col-md-12 col-sm-4 col-12 mt-md-3 mt-sm-0 mt-3"
              >
                <b-button
                  v-if="enableEdit || enableOpen"
                  size="sm"
                  @click.prevent="editItem(index)"
                  class="mr-sm-1 mr-4 mobile-btn-45"
                >
                  <span v-if="enableEdit"
                    ><i class="fa fa-edit"></i> Editar</span
                  >
                  <span v-else-if="enableOpen"
                    ><i class="fa fa-eye"></i> Abrir</span
                  >
                </b-button>
                <b-button
                  v-if="enableDelete"
                  size="sm"
                  variant="danger"
                  @click.prevent="openDeleteModal(index)"
                  class="mobile-btn-45"
                >
                  <i class="fa fa-trash"></i> Excluir
                </b-button>
                <b-button
                  v-if="enableCancel"
                  size="sm"
                  variant="danger"
                  @click.prevent="openCancelModal(index)"
                  class="mobile-btn-45"
                >
                  <i class="fa fa-times"></i> Cancelar
                </b-button>
              </div>
              <div
                v-if="item.processStatus"
                class="col-md-12 col-sm col-12 mt-md-1 mt-sm-0 mt-1 ml-md-0 ml-sm-n5"
              >
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <stock-adjustment-process-status-badge
                      :stockAdjustment="item"
                    ></stock-adjustment-process-status-badge>
                  </div>
                  <div
                    v-if="
                      item.stockAdjustmentItemsLength &&
                      item.numberOfItemsAdded &&
                      item.numberOfItemsAdded < item.stockAdjustmentItemsLength
                    "
                  >
                    <b-progress
                      :max="item.stockAdjustmentItemsLength"
                      style="min-width: 150px"
                    >
                      <b-progress-bar :value="item.numberOfItemsAdded">
                        {{ item.numberOfItemsAdded }} /
                        {{ item.stockAdjustmentItemsLength }}
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loadingRows" class="m-3">
        <vcl-facebook v-for="index in perPage" :key="index" :height="100" style="margin-right: 100%;"></vcl-facebook>
      </div>

      <!-- <nav>
        <b-pagination
          v-if="totalRows"
          align="center"
          :limit="10"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-text="Anterior"
          next-text="Próximo"
          v-model="currentPage"
          @change="pageChanged"
          :disabled="loadingRows"
        />
      </nav> -->

      <confirmation-modal
        id="delete-confirmation-modal"
        msg="Tem certeza de que deseja excluir este pedido?"
        :ok="deleteOrder"
      ></confirmation-modal>

      <confirmation-modal
        id="cancel-confirmation-modal"
        msg="Tem certeza de que deseja cancelar este pedido?"
        :ok="cancelOrder"
      ></confirmation-modal>

      <order-info-modal
        ref="orderInfoModal"
        :movementType="movementType"
        :role="role"
        :saving="saving"
        :updateSaving="updateSaving"
        :startPrepare="startPrepare"
        :config="config"
        :url="url"
      ></order-info-modal>

    </div>
  </div>
</template>

<script>
import { LoadingAnimation } from "@/components/loaders";
import { httpClient } from "@/service";
import ConfirmationModal from "@/components/common/confirmation-modal";
import shared from "@/shared/shared";
import StockAdjustmentProcessStatusBadge from "@/components/stock-adjustment-process-status-badge";
import firebase from "../../firebase";
import { VclFacebook } from 'vue-content-loading';
import { auth } from "@/service";
import OrderInfoModal from '@/components/modals/order-info-modal'

const PAGE_SIZE = 10;

export default {
  name: "orders-prepare-table",

  components: {
    LoadingAnimation,
    ConfirmationModal,
    StockAdjustmentProcessStatusBadge,
    VclFacebook,
    OrderInfoModal
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    urlStart: {
      type: String,
      require,
    },
    role: {
      type: String,
      require,
    },
    fields: {
      type: [Array, Object],
      default: () => [],
    },
    url: {
      type: String,
    },
    resourceName: {
      type: String,
    },
    movementType: {
      type: String,
      require,
    },
    enableEdit: {
      type: Boolean,
      default: false,
    },
    enableDelete: {
      type: Boolean,
      default: false,
    },
    enableCancel: {
      type: Boolean,
      default: false,
    },
    enableOpen: {
      type: Boolean,
      default: false,
    },
    urlEdit: {
      type: String,
    },
  },

  data: () => {
    return {
      currentPage: 1,
      perPage: PAGE_SIZE,
      hasNextRow: true,
      fieldsTasks: [
        { key: "allocationStartDate", label: "Iniciada em" },
        { key: "allocationEndDate", label: "Finalizada em" },
      ],
      items: [],
      deleteIndex: null,
      loadingRows: false,
      lastWarehouse: {},
      lastTicketStatus: {},
      lastRoute: {},
      lastFilter: null,
      lastOperation: {},
      lastSort: "asc",
      lastItem: {},
      lastLocation: {},
      cancelIndex: null,
      saving: false,
      cancelledOrderId: null,
      pendingStatus: null,
      firebaseAdjustmentsDb: firebase.ref("stock-adjustments"),
      config: {},
      ownRoute: null
    };
  },

  created() {
    this.ownRoute = this.$route.fullPath;
    let oldPage = Number(
      shared.getLocalStorageString(
        this.movementType + "/" + this.id + "/currentPage"
      )
    );

    if (!oldPage) {
      oldPage = 1;
    }

    if (oldPage <= 10) {
      this.perPage = oldPage * this.perPage;
    } else {
      this.currentPage = oldPage;
    }

    this.authUser = shared.getLocalStorageObj("auth/user");
    this.config = shared.getConfig();
    this.listenToScrollBottom();
  },

  methods: {
    getItems() {
      return this.items;
    },

    getSeparationOrderByField() {
      if (this.config
        && this.config.itemsOutConfig
        && this.config.itemsOutConfig.orderByField) {
        if (!Array.isArray(this.config.itemsOutConfig.orderByField)) {
          this.config.itemsOutConfig.orderByField = [this.config.itemsOutConfig.orderByField];
        }
        if (this.config.itemsOutConfig.orderByField.length) {
          return this.config.itemsOutConfig.orderByField;
        } else if (this.config.itemsOutConfig.orderByField.value && this.config.itemsOutConfig.orderByField.value.length) {
          return this.config.itemsOutConfig.orderByField.value;
        }
      }

      return 'departureDate';
    },

    getPage(context) {
      if (this.loadingRows) {
        return;
      }

      var sortBy = null;
      if (
        !["ENT", "SAI"].includes(this.movementType) ||
        (context.operation &&
          ["AJU", "TRANSFERENCIA", "TRANSFORMACAO", "PRODUCAO"].includes(
            context.operation.value
          ))
      ) {
        sortBy = [{
          condition: { field: `createdAt` },
          direction: this.lastSort.toUpperCase()
        }];
      } else {
        sortBy = this.getSeparationOrderByField().map(f => {
          return {
              condition: { field: f.value },
              direction: this.lastSort.toUpperCase()
          };
        });
      }

      let isOperationEntOrSai = context.operation && context.operation.value && (context.operation.value == "ENT" || context.operation.value == "SAI");

      let conditions = [
        {
          field: "deletedAt",
          conditionalOperator: "IS_NULL",
        }
      ];

      if (!["AJU", "TRANSFORMACAO"].includes(this.movementType)) {
        conditions.push({
          field: "fiscalOperation.type",
          value: this.movementType,
        });

        if (this.movementType === "SAI" && this.role === "PEN") {
          this.getPendingStatus();

          if (this.pendingStatus != null && this.pendingStatus.length) {
            let temp = {
              logicalOperator: "OR",
              conditions: []
            };

            if (!isOperationEntOrSai) {
              temp.conditions.push(...[
                {
                  field: "transferId",
                  conditionalOperator: "IS_NOT_NULL",
                },
                {
                  field: "stockAdjustmentId",
                  conditionalOperator: "IS_NOT_NULL",
                },
                {
                  field: "transformationMSId",
                  conditionalOperator: "IS_NOT_NULL",
                },
                {
                  field: "productionMSId",
                  conditionalOperator: "IS_NOT_NULL",
                }
              ]);
            }

            temp.conditions.push({
              logicalOperator: "AND",
              conditions: [
                {
                  field: "type",
                  value: "P",
                },
                {
                  field: "status",
                  conditionalOperator: "IN",
                  value: this.pendingStatus,
                },
              ],
            });

            conditions.push(temp);
          }
        }

        if (this.movementType == 'ENT'&&
          this.authUser &&
          this.authUser.companyGroup &&
          this.authUser.companyGroup.parameter
        ) {
          let parameter = this.authUser.companyGroup.parameter
          let allocationFiscalOperationStockOptions;
          let allocationFiscalOperationEffectiveOptions;

          if(parameter.allocationFiscalOperationStockOptions){
            allocationFiscalOperationStockOptions = JSON.parse(parameter.allocationFiscalOperationStockOptions).map(i => i.value)
          }

          if(parameter.allocationFiscalOperationEffectiveOptions){
            allocationFiscalOperationEffectiveOptions = JSON.parse(parameter.allocationFiscalOperationEffectiveOptions).map(i => i.value)
          }

          if (
            (allocationFiscalOperationStockOptions && allocationFiscalOperationStockOptions.length)
            || (allocationFiscalOperationEffectiveOptions && allocationFiscalOperationEffectiveOptions.length)
          ) {
            let condition = {
              logicalOperator: 'OR',
              conditions: []
            };

            if (!isOperationEntOrSai) {
              condition.conditions.push(...[
                {
                  field: "transferId",
                  conditionalOperator: "IS_NOT_NULL",
                },
                {
                  field: "stockAdjustmentId",
                  conditionalOperator: "IS_NOT_NULL",
                },
                {
                  field: "transformationMSId",
                  conditionalOperator: "IS_NOT_NULL",
                },
                {
                  field: "productionMSId",
                  conditionalOperator: "IS_NOT_NULL",
                }
              ]);
            }

            let temp = [];

            if(allocationFiscalOperationStockOptions && allocationFiscalOperationStockOptions.length){
              temp.push({
                field: "fiscalOperation.operationStock",
                conditionalOperator: 'IN',
                value: allocationFiscalOperationStockOptions
              });
            }

            if(allocationFiscalOperationEffectiveOptions && allocationFiscalOperationEffectiveOptions.length){
              temp.push({
                field: "fiscalOperation.operationEffective",
                conditionalOperator: 'IN',
                value: allocationFiscalOperationEffectiveOptions
              });
            }

            if (temp.length) {
              condition.conditions.push({
                conditions: temp
              });
            }

            conditions.push(condition);
          }
        }
      }

      if (this.role === "PEN") {
        conditions.push({
          logicalOperator: "OR",
          conditions: [
            {
              field: "movementStatus",
              value: "L",
            },
            {
              field: "movementStatus",
              conditionalOperator: "IS_NULL",
            },
          ],
        });
      } else if (this.role === "CAN") {
        conditions.push({
          field: "movementStatus",
          value: "X",
        });
      } else if (this.role === "CHE") {
        conditions.push({
          field: "movementStatus",
          value: "C",
        });
      } else {
        if (this.movementType === "SAI") {
          conditions.push({
            field: "movementStatus",
            conditionalOperator: "IN",
            value: ["O", "C", "K", "A", "E"],
          });
        } else {
          conditions.push({
            field: "movementStatus",
            value: "O",
          });
        }
      }

      if (context.filter && context.filter.length) {
        let filterConditions = [
          {
            field: "number",
            value: context.filter,
          },
          {
            field: "ref",
            conditionalOperator: "LIKE_START",
            value: context.filter,
          },
        ];

        if (this.movementType !== "AJU") {
          filterConditions.push({
            field: "documentNumber",
            value: context.filter,
          });
        }

        conditions.push({
          logicalOperator: "OR",
          conditions: filterConditions,
        });
      }

      if (context.warehouse && context.warehouse.id) {
        if (this.movementType === "TRANSFORMACAO") {
          conditions.push({
            logicalOperator: "OR",
            conditions: [
              {
                field: "entriesStockLocale.id",
                value: context.warehouse.id,
              },
              {
                field: "exitsStockLocale.id",
                value: context.warehouse.id,
              },
            ],
          });
        } else {
          conditions.push({
            field: "stockLocale.id",
            conditionalOperator: "EQUALS",
            value: context.warehouse.id,
          });
        }
      }

      if (context.ticketStatus && context.ticketStatus.id) {
        conditions.push({
          field: "ticketStatus.id",
          conditionalOperator: "EQUALS",
          value: context.ticketStatus.id,
        });
      }

      if (context.route && context.route.id) {
        conditions.push({
          field: "route.id",
          conditionalOperator: "EQUALS",
          value: context.route.id,
        });
      }

      if (context.operation && context.operation.value) {
        if (isOperationEntOrSai) {
          conditions.push({
            field: "transferId",
            conditionalOperator: "IS_NULL",
          });
          conditions.push({
            field: "transformationMSId",
            conditionalOperator: "IS_NULL",
          });
          conditions.push({
            field: "productionMSId",
            conditionalOperator: "IS_NULL",
          });
          conditions.push({
            field: "stockAdjustmentId",
            conditionalOperator: "IS_NULL",
          });
        } else if (context.operation.value == "TRANSFERENCIA") {
          conditions.push({
            field: "transferId",
            conditionalOperator: "IS_NOT_NULL",
          });
        } else if (context.operation.value == "TRANSFORMACAO") {
          conditions.push({
            field: "transformationMSId",
            conditionalOperator: "IS_NOT_NULL",
          });
        } else if (context.operation.value == "PRODUCAO") {
          conditions.push({
            field: "productionMSId",
            conditionalOperator: "IS_NOT_NULL",
          });
        } else if (context.operation.value == "AJU") {
          conditions.push({
            field: "stockAdjustmentId",
            conditionalOperator: "IS_NOT_NULL",
          });
        }
      }

      if ((context.item && context.item.id) || (context.location && context.location.id)) {
        let field = "orderItems";

        switch (this.movementType) {
          case "AJU":
            field = "stockAdjustmentItems";
            break;
          case "TRANSFORMACAO":
            field = "details";
            break;
        }

        if (context.item && context.item.id) {
          conditions.push({
            field: field + ".item.id",
            value: context.item.id,
          });
        }

        if (context.location && context.location.id) {
          conditions.push({
            field: field + ".location.id",
            value: context.location.id,
          });
        }
      }

      let selectAndWhere = {
        select: [ 'id', 'ref', 'movementStatus', 'number', 'createdAt'],
        where: {
          conditions: conditions,
          //groupBy: ["id"],
          orderBies: sortBy
        }
      }

      if (this.movementType === 'AJU') {
        selectAndWhere.select.push(...[
          'processStatus', 'type', 'countOption', 'imported', 'generatedFromOrder.id', 'generatedFromOrder.number',
          'generatedFromOrder.fiscalOperation.type', 'stockLocale.name', 'numberOfItemsAdded',
          //'count(stockAdjustmentItems.id) as stockAdjustmentItemsLength'
        ]);
      } else if (this.movementType === 'TRANSFORMACAO') {
        selectAndWhere.select.push(...[
          'documentNumber', 'entriesStockLocale.name', 'exitsStockLocale.name', 'system'
        ]);
      } else {
        selectAndWhere.select.push(...[ 'company.ref','departureDate', 'deliveryDate',
          'documentNumber', 'system', 'customer.person.name', 'stockLocale.name', 'transferId', 'transformationMSId', 'productionMSId',
          'stockAdjustmentId', 'allocationStartDate', 'allocationEndDate', 'stockAdjustment.imported', 'stockAdjustment.type', 'fiscalOperation.type',
          //'quot(sum(orderItems.alocatedQuantity), sum(orderItems.quantity)) as alocatedQuotient',
          'ticketStatus.situation', 'ticketStatus.description'
        ]);
      }

      this.loadingRows = true;
      return httpClient
        .post(
          `${context.apiUrl}/select-and-where?sliced=true&page=${context.currentPage - 1}&size=${
            context.perPage
          }`,
          selectAndWhere
        )
        .then(({ data }) => {
          this.loadingRows = false;
          this.hasNextRow = !data.data.last;
          data.data.content = this.setTempFields(data.data.content);

          if (!this.items) {
            this.items = [];
          }

          this.items.push(...data.data.content);
          this.currentPage = data.data.number + 1;

          if (this.perPage > PAGE_SIZE) {
            this.currentPage = this.perPage / PAGE_SIZE;

            this.$nextTick(() => {
              shared.restoreScrollPosition(this.movementType + "/" + this.id + '/scrollPosition');
            });
          }

          shared.setLocalStorageString(this.movementType + "/" + this.id + '/currentPage', this.currentPage);
          this.perPage = PAGE_SIZE;

          this.firebaseAdjustmentsDb.child(this.authUser.companyGroup.alias).on(
            "child_changed",
            this.updateItemsStatuses
          );

          this.$root.$emit("bv::refresh::table", this.id);
          return this.items;
        })
        .catch((error) => {
          this.loadingRows = false;
          if (error.message) {
            this.$notify.error(error);
          }
          this.items = [];
          return [];
        });
    },

    updateItemsStatuses(item) {
      let itemValue = item.val();
      let itemKey = item.key;
      if (itemValue && itemKey) {
        let index = this.items.map((x) => x.id).indexOf(itemKey);
        let _item = { ...this.items[index], ...itemValue };
        this.items.splice(index, 1, _item);
      }
    },

    pageChanged(page) {
      this.getPage({
        apiUrl: this.url,
        currentPage: page,
        perPage: this.perPage,
        sortBy: "",
        sortDesc: false,
        warehouse: this.lastWarehouse,
        ticketStatus: this.lastTicketStatus,
        route: this.lastRoute,
        operation: this.lastOperation,
        item: this.lastItem,
        filter: this.lastFilter,
        location: this.lastLocation,
      });
    },

    startPrepare(order) {
      this.$router.push(this.urlStart + order.id);
    },

    filter(options) {
      /*if (
        options.filterText != this.lastFilter ||
        (options.warehouse &&
          this.lastWarehouse &&
          options.warehouse.id != this.lastWarehouse.id) ||
        (options.warehouse && !this.lastWarehouse) ||
        (!options.warehouse && this.lastWarehouse) ||
        (options.ticketStatus &&
        this.lastTicketStatus &&
        options.ticketStatus.id != this.lastTicketStatus.id) ||
        (options.ticketStatus && !this.lastTicketStatus) ||
        (!options.ticketStatus && this.lastTicketStatus) ||
        (options.ticketStatus &&
        this.lastRoute &&
        options.route.id != this.lastRoute.id) ||
        (options.route && !this.lastRoute) ||
        (!options.route && this.lastRoute) ||
        (options.item && this.lastItem && options.item.id != this.lastItem.id) ||
        (options.item && !this.lastItem) ||
        (!options.item && this.lastItem) ||
        (location && this.lastLocation && location.id != this.lastLocation.id) ||
        (location && !this.lastLocation) ||
        (!location && this.lastLocation) ||
        (options.operation &&
          this.lastOperation &&
          options.operation.value != this.lastOperation.value) ||
        (options.operation && !this.lastOperation) ||
        (!options.operation && this.lastOperation)
        || (options.sort != this.lastSort)
      ) {*/
        options.page = 1;
        this.items = [];
        this.hasNextRow = true;
      /*} else if (this.items && this.items.length) {
        return;
      }*/

      this.lastFilter = options.filterText;
      this.lastWarehouse = options.warehouse;
      this.lastTicketStatus = options.ticketStatus;
      this.lastRoute = options.route;
      this.lastOperation = options.operation;
      this.lastSort = options.sort;
      this.lastItem = options.item;
      this.lastLocation = location;

      if (options.page) {
        this.currentPage = options.page;
      }

      this.getPage({
        apiUrl: this.url,
        currentPage: this.currentPage,
        perPage: this.perPage,
        sortBy: "",
        sortDesc: false,
        filter: options.filterText,
        warehouse: options.warehouse,
        ticketStatus: options.ticketStatus,
        route: options.route,
        operation: options.operation,
        item: options.item,
        location: location
      });
    },

    setTempFields(items) {
      if (items) {
        items.forEach((item) => {
          if (item.fiscalOperation) {
            item.fiscalOperation.showType = shared.setOrderShowType(item);
          }

          if (
            item.generatedFromOrder &&
            item.generatedFromOrder.fiscalOperation
          ) {
            item.generatedFromOrder.fiscalOperation.showType = shared.setOrderShowType(
              item.generatedFromOrder
            );
          }

          let orderByField = 'createdAt';

          if (item.fiscalOperation && ['ENT', 'SAI'].includes(item.fiscalOperation.showType)) {
            orderByField = this.getSeparationOrderByField();
          }

          if (!item[orderByField]) {
            orderByField = 'createdAt';
          }

          if (item[orderByField]) {
            item.formattedCreatedAt = shared.formatDate(item[orderByField]);
          }

          if (item.departureDate) {
            item.formattedDepartureDate = shared.formatDate(item.departureDate, { dateOnly: true });
          }

          if (item.deliveryDate) {
            item.formattedDeliveryDate = shared.formatDate(item.deliveryDate, { dateOnly: true });
          }

          if (item.allocationStartDate) {
            item.formattedStartDate = shared.formatDate(
              item.allocationStartDate
            );
          }

          if (item.allocationEndDate) {
            item.formattedEndDate = shared.formatDate(item.allocationEndDate);
          }

          if (item.ref && item.ref.length) {
            item.refLabel = item.ref.replace(/\D/g, "");
          }
        });
      }

      return items;
    },

    openDeleteModal(index) {
      this.deleteIndex = index;
      this.$bvModal.show("delete-confirmation-modal");
    },

    editItem(index) {
      let url = this.urlEdit;

      if (this.items[index].type == "ARM") {
        url = "/orders/item-adjustment/edit/";
      } else if (this.items[index].type == "EEL") {
        url = "/orders/location-and-lot-item-adjustment/edit/";
      }

      this.$router.push(url + this.items[index].id);
    },

    deleteOrder() {
      this.saving = true;
      httpClient
        .delete(this.url, this.items[this.deleteIndex].id)
        .then(({ data }) => {
          this.saving = false;
          this.$notify.success("Pedido excluído com sucesso");
          this.items.splice(this.deleteIndex, 1);
          this.deleteIndex = null;
        })
        .catch((error) => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError("Houve um erro ao excluir o pedido");
          }
        });
    },

    operationBadgeClassObject(item) {
      return shared.operationBadgeClassObject(item);
    },

    adjustmentTypeBadgeClassObject(item) {
      return {
        "badge-danger": item.type === "ARM",
        "badge-primary": item.type === "EEL",
      };
    },

    getAdjustmentTypeDescription(item) {
      if (item.type === "ARM") {
        return "Ajuste - Por Item";
      } else if (item.type === "EEL") {
        return "Ajuste - Inventário";
      } else {
        return "";
      }
    },

    openCancelModal(index) {
      this.cancelIndex = index;
      this.$bvModal.show("cancel-confirmation-modal");
    },

    cancelOrder() {
      this.saving = true;
      httpClient
        .post(this.url + "/cancel/" + this.items[this.cancelIndex].id)
        .then(({ data }) => {
          this.saving = false;
          this.cancelledOrderId = this.items[this.cancelIndex].id;
          this.$notify.success("Pedido cancelado com sucesso");
          this.items.splice(this.cancelIndex, 1);
          this.cancelIndex = null;

          if (["ENT", "SAI"].includes(this.movementType)) {
            this.$bvModal.show(
              "generate-stock-adjustment-with-reverse-movements-after-cancelling-confirmation-modal"
            );
          }
        })
        .catch((error) => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError("Houve um erro ao cancelar o pedido");
          }
        });
    },

    getPendingStatus() {
      if (!this.pendingStatus) {
        this.pendingStatus = this.setPendingStatus();
      }

      return this.pendingStatus;
    },

    setPendingStatus() {
      let statuses = [];

      if (
        this.authUser &&
        this.authUser.companyGroup &&
        this.authUser.companyGroup.parameter
      ) {
        if (this.authUser.companyGroup.parameter.separationPendingStatus) {
          statuses.push(
            this.authUser.companyGroup.parameter.separationPendingStatus
          );
        }

        if (this.authUser.companyGroup.parameter.separationStartedStatus) {
          statuses.push(
            this.authUser.companyGroup.parameter.separationStartedStatus
          );
        }
      }

      return statuses;
    },

    listenToScrollBottom() {
      window.onscroll = () => {
        if (
          !this.loadingRows
          && !this.saving
          && this.hasNextRow
          && this.$route.fullPath === this.ownRoute
          && auth.isScrollOnBottom()
        ) {
          this.pageChanged(this.currentPage+1);
        }
      }
    },

    beforeRouteLeave() {
      shared.saveScrollPosition(this.movementType + "/" + this.id + '/scrollPosition');
    },

    updateSaving(saving) {
      this.saving = saving;
    },

    openOrderInfoModal(item, type) {
      this.$refs.orderInfoModal.openOrderInfoModal(item, type);
    }
  },
};
</script>
